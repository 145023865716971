<!-- =========================================================================================
  File Name: ECommerceShop.vue
  Description: eCommerce Shop Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <ComingSoon v-if="!showShop" />
    <div v-if="showShop">
      <div class="flex flex-col lg:flex-row lg:items-end">
        <div v-if="hasOrganization" class="w-full lg:w-1/5 bg-white p-3 rounded-lg mb-4 shadow"
          style="background: white">
          <ClinicSwap :title="`Delivery to`" :isDoctor="isDoctor" />
        </div>
        <div :class="
          hasOrganization
            ? 'w-full lg:w-4/5 algolia-header mb-4 px-6'
            : 'algolia-header mb-4 w-full'
        ">
          <div class="flex flex-col lg:flex-row">
            <!-- SEARCH BAR -->
            <div class="relative w-full lg:pr-5">
              <!-- SEARCH INPUT -->
              <div class="flex flex-row bg-white rounded-lg shadow">
                <div class="w-16 flex justify-center items-center">
                  <span class="material-icons text-primary"> search </span>
                </div>
                <input type="text" placeholder="Search here" v-model="filterParams.search" class="
                    border-0
                    w-full
                    h-16
                    rounded-lg
                    text-lg
                    placeholder-gray-50
                  " @keyup="queryProduct('search', null)" />
                <!-- <vs-input
              class="w-full vs-input-no-border"
              placeholder="Search here"
              v-model="filterParams.search"
              @keyup="queryProduct('search', null)"
              size="large"
            /> -->
              </div>
            </div>

            <div class="hidden lg:block bg-white rounded-lg shadow p-1">
              <!-- SORTING -->
              <vs-select vs-multiple autocomplete v-model="filterParams.sortBy" @change="queryProduct('sort', null)">
                <vs-select-item :key="item.id" :value="item.value" :text="item.label" v-for="item in sortItems"
                  :clearable="false" />
              </vs-select>
            </div>
          </div>
        </div>
      </div>
      
      <!-- right category list -->
      <div id="algolia-content-container" class="flex flex-col lg:flex-row mt-24">
        <div class="bg-white w-full shadow rounded-md lg:hidden mb-3">
          <div class="
              flex
              text-center
              py-3
              px-4
              rounded
              items-center
              justify-center
              cursor-pointer
              w-full
            " @click="toggleFilter">
            <span class="text-sm text-lg text-black">Filter</span>
            <feather-icon v-if="showFilter" icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1 text-primary" />
            <feather-icon v-if="!showFilter" icon="ChevronRightIcon" svgClasses="h-4 w-4" class="ml-1 text-primary" />
          </div>
          <div v-if="showFilter">
            <div v-for="category in treeCategories" :key="category._id">
              <div class="text-lg p-2 cursor-pointer" @click="show(category)">
                <feather-icon v-if="category.show" icon="ChevronDownIcon" svgClasses="h-4 w-4"
                  class="ml-1 text-primary" /><span v-if="category.show" class="text-primary">{{
                      category.name
                  }}</span>
                <feather-icon v-if="!category.show" icon="ChevronRightIcon" svgClasses="h-4 w-4" class="ml-1" /><span
                  v-if="!category.show">{{ category.name }}</span>
              </div>

              <div v-if="category.show">
                <div v-for="subCategory in category.ancestors" :key="subCategory._id" class="pl-4 text-base opacity-75"
                  @click="selectChild(category, subCategory)">
                  <div v-if="subCategory.show" class="p-2 text-primary cursor-pointer"
                    :class="!subCategory.hasIcon && 'ml-6'">
                    <feather-icon v-if="subCategory.hasIcon" icon="ChevronRightIcon" svgClasses="h-4 w-4"
                      class="ml-1" />
                    {{ subCategory.name }}
                  </div>
                  <div v-if="!subCategory.show" class="p-2 cursor-pointer" :class="!subCategory.hasIcon && 'ml-6'">
                    <feather-icon v-if="subCategory.hasIcon" icon="ChevronRightIcon" svgClasses="h-4 w-4"
                      class="ml-1" />
                    {{ subCategory.name }}
                  </div>
                  <div v-if="subCategory.show">
                    <div v-for="childCategory in subCategory.ancestors" :key="childCategory._id"
                      class="pl-6 text-base opacity-75" @click.stop="selectSubChild(subCategory, childCategory)">
                      <div v-if="childCategory.show" class="p-2 text-primary cursor-pointer">
                        <li>{{ childCategory.name }}</li>
                      </div>
                      <div v-if="!childCategory.show" class="p-2 cursor-pointer">
                        <li>{{ childCategory.name }}</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white p-5 w-2/5 lg:w-1/5 hidden lg:block rounded-lg shadow">
          <div v-for="category in treeCategories" :key="category._id">
            <div class="text-lg p-2 cursor-pointer" @click="show(category)">
              <feather-icon v-if="category.show" icon="ChevronDownIcon" svgClasses="h-4 w-4"
                class="ml-1 text-primary" /><span v-if="category.show" class="text-primary">{{
                    category.name
                }}</span>
              <feather-icon v-if="!category.show" icon="ChevronRightIcon" svgClasses="h-4 w-4" class="ml-1" /><span
                v-if="!category.show">{{ category.name }}</span>
            </div>

            <div v-if="category.show">
              <div v-for="subCategory in category.ancestors" :key="subCategory._id" class="pl-4 text-base opacity-75"
                @click="selectChild(category, subCategory)">
                <div v-if="subCategory.show" class="p-2 text-primary cursor-pointer"
                  :class="!subCategory.hasIcon && 'ml-6'">
                  <feather-icon v-if="subCategory.hasIcon" icon="ChevronRightIcon" svgClasses="h-4 w-4" class="ml-1" />
                  {{ subCategory.name }}
                </div>
                <div v-if="!subCategory.show" class="p-2 cursor-pointer" :class="!subCategory.hasIcon && 'ml-6'">
                  <feather-icon v-if="subCategory.hasIcon" icon="ChevronRightIcon" svgClasses="h-4 w-4" class="ml-1" />
                  {{ subCategory.name }}
                </div>
                <div v-if="subCategory.show">
                  <div v-for="childCategory in subCategory.ancestors" :key="childCategory._id"
                    class="pl-6 text-base opacity-75" @click.stop="selectSubChild(subCategory, childCategory)">
                    <div v-if="childCategory.show" class="p-2 text-primary cursor-pointer">
                      <li>{{ childCategory.name }}</li>
                    </div>
                    <div v-if="!childCategory.show" class="p-2 cursor-pointer">
                      <li>{{ childCategory.name }}</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:px-5 lg:w-4/5">
          <!-- STORE LIMITATION -->
          <div>
            <vs-card :key="limitation._id" v-for="limitation in limitationsList ">
              <!--  <div class="flex justify-between"> 
                <span class="text-base lg:text-lg md:text-lg font-semibold">{{limitation.limitation_name}}</span> 
                <span class="font-semibold">{{`min quantity : ${limitation.min_qty} - max quantity : ${limitation.max_qty}`}}</span>
              </div>
              <p class="text-base">Product/s</p>
              <p class="text-base" :key="product._id"  v-for="product in limitation.products">{{product.product?product.product.name:""}}</p> -->
              <p v-if="limitation.isAboveMax" class=" text-base text-danger">{{ limitation.display_message_max }}</p>
              <p v-if="limitation.isBelowMin" class=" text-base text-danger">{{ limitation.display_message_min }}</p>
              <p v-if="limitation.isBellowAmount" class="text-base text-danger">
                  {{ limitation.display_purchase_message_min }}
                </p>
            </vs-card>
          </div>
          <!-- STORE LIMITATION -->
          <!-- SEARCH RESULT -->
          <div class="flex flex-col md:flex-row md:flex-wrap lg:flex-col">
            <div class="
                items-list-view
                mb-base
                w-full
                md:w-1/2
                lg:w-full
                md:p-2
                lg:py-0
              " v-for="item in filteredItems" :key="item.objectID">
              <item-list-view :item="item" @quantityChange="quantityChange" :index="index">
                <!-- SLOT: ACTION BUTTONS -->
                <template slot="action-buttons" scope="{item2}">
                  <div class="
                      item-view-secondary-action-btn
                      bg-primary
                      lg:w-48
                      py-4
                      rounded-lg
                      items-center
                      justify-center
                      text-white
                      cursor-pointer
                      text-center
                      cursor-pointer
                      add-to-cart-disabled
                    " v-if="
                      item.canBuy &&
                      item2.selectedQuantity <
                      item2.inventory.requiredQuantity &&
                      item.isAvailable
                    ">
                    <feather-icon icon="ShoppingBagIcon" svgClasses="h-4 w-4" />
                    <span class="text-sm font-base">Add to Cart</span>
                  </div>

                  <div class="
                      item-view-secondary-action-btn
                      bg-primary
                      w-1/2
                      lg:w-48
                      rounded-lg
                      py-3
                      items-center
                      justify-center
                      text-white
                      cursor-pointer
                      text-center
                    " @click="cartButtonClicked(item)" v-if="item.canBuy && item.isAvailable">
                    <span v-if="item.type === 'lead'" class="text-base font-base">
                      View Product
                    </span>
                    <span v-else class="text-base font-base">
                      Add to Cart
                    </span>
                  </div>

                  <div v-if="!item.canBuy" class="w-full lg:w-48">
                    <div class="
                        item-view-secondary-action-btn
                        bg-primary
                        w-full
                        lg:w-48
                        py-3
                        rounded-lg
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                        text-center
                      " @click="popupActive = true">
                      <span class="text-sm font-base">Contact Fresh</span>
                    </div>
                  </div>

                  <div v-if="!item.isAvailable && item.canBuy" class="w-full lg:w-48">
                    <div class="
                        item-view-secondary-action-btn
                        bg-gray-400
                        w-full
                        lg:w-48
                        py-3
                        rounded-lg
                        items-center
                        justify-center
                        text-white
                        cursor-pointer
                        text-center
                      ">
                      <span class="text-sm font-base">Out of Stock</span>
                    </div>
                  </div>
                </template>
              </item-list-view>
            </div>
          </div>

          <vs-popup class="holamundo" title="Supplier Account Required" :active.sync="popupActive">
            <div class="flex flex-no-wrap">
              <div class="w-1/6 flex items-center justify-center">
                <vs-icon icon="clear" size="medium" bg="#f9d6d6" color="#b21f1e" round></vs-icon>
              </div>
              <div class="w-5/6">
                <ContactFresh />
              </div>
            </div>
            <div class="flex justify-end mt-5">
              <vs-button class="mr-4 mb-4" @click="popupActive = false">
                I understand
              </vs-button>
            </div>
          </vs-popup>

          <!-- PAGINATION -->
          <paginate v-if="filteredItems.length > 0" :page-count="totalPage" :click-handler="handleChangePage"
            class="pagination" :page-range="9" :prevText="'<'" :nextText="'>'"></paginate>

          <!-- ALGOLIA LOGO -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AisInstantSearchSsr,
  AisClearRefinements,
  AisConfigure,
  AisHierarchicalMenu,
  AisHits,
  AisNumericMenu,
  AisPagination,
  AisRangeInput,
  AisRatingMenu,
  AisRefinementList,
  AisSearchBox,
  AisSortBy,
  AisStats,
  createServerRootMixin,
} from "vue-instantsearch";

import { getSearchClient } from "../../../utils/storeAlgoliaSearch";
import { VTree, VSelectTree } from "vue-tree-halower";
import { mapActions } from "vuex";
import TreeItem from "../../components/order-center/TreeItem";
import ClinicSwap from "../../components/shop/clinic.vue";
import _ from "lodash";
import ComingSoon from "../../clinic/ClinicShopComingSoon.vue";
import LDClient from "launchdarkly-js-client-sdk";
import StoreHelper from "../../../mixins/Store.vue";
import ContactFresh from "../../components/ContactFresh";

const searchClient = getSearchClient();
export default {
  name: "OrderCenter",
  mixins: [
    StoreHelper,
    createServerRootMixin({
      searchClient,
      indexName: "instant_search",
    }),
  ],
  components: {
    TreeItem,
    ClinicSwap,
    ComingSoon,
    ItemGridView: () =>
      import("@/views/components/order-center/ItemGridView.vue"),
    ItemListView: () =>
      import("@/views/components/order-center/ItemListView.vue"),
    AisClearRefinements,
    AisInstantSearchSsr,
    AisConfigure,
    AisHierarchicalMenu,
    AisHits,
    AisNumericMenu,
    AisPagination,
    AisRangeInput,
    AisRatingMenu,
    AisRefinementList,
    AisSearchBox,
    AisSortBy,
    AisStats,
    VTree,
    VSelectTree,
    ContactFresh
  },
  data() {
    return {
      hasOrganization: false,
      showShop: false,
      showFilter: false,
      popupActive: false,
      filterParams: {
        categoriesOptions: [],
        vendorOptions: [],
        sortBy: "date_desc",
        search: "",
        page: 1,
        limit: 12,
      },
      treeCategories: [],
      page: 1,
      suppliers: [],
      productParams: {
        page: 1,
        limit: 12,
      },
      totalDocs: 0,
      searchClient: getSearchClient(),
      sortItems: [
        {
          value: "order_asc",
          label: "Sort by featured",
          id: 1,
        },
        {
          value: "price_asc",
          label: "Lowest Price",
          id: 2,
        },
        {
          value: "price_desc",
          label: "Highest Price",
          id: 3,
        },
      ],

      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,
      currentItemView: "item-list-view",
      filteredItems: [
        {
          price: 10,
          description: "this is very serious condition for appolo test ",
          image: "",
        },
      ],
      numericItems: [
        {
          label: "All",
        },
        {
          label: "<= $10",
          end: 10,
        },
        {
          label: "$10 - $100",
          start: 10,
          end: 100,
        },
        {
          label: "$100 - $500",
          start: 100,
          end: 500,
        },
        {
          label: ">= $500",
          start: 500,
        },
      ],
      isDoctor: false,
      AllLimitations: [],
      limitationsList: []
    };
  },
  computed: {
    toValue() {
      return (value, range) => [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },
    totalPage: function () {
      return this.totalDocs / 10 >
        parseInt(this.totalDocs / this.productParams.limit)
        ? parseInt(this.totalDocs / this.productParams.limit) + 1
        : parseInt(this.totalDocs / this.productParams.limit);
    },
    totalItemQuantityIncart() {
      return (itemId) =>
        this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },

    // GRID VIEW
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    ...mapActions("ecommerce", ["fetchFilterData", "getCartItemsFromApi", "productFilterListing"]),
    ...mapActions("appUser", ["fetchAppUserIndex"]),
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    onLaunchDarklyReady() {
      if (this.ldClient.allFlags().shop) {
        this.showShop = this.ldClient.allFlags().shop;
      }
    },
    show(category) {
      let treeCategories = _.cloneDeep(this.treeCategories);
      for (let i = 0; i < treeCategories.length; ++i) {
        let parentCategory = treeCategories[i];
        if (parentCategory._id == category._id) {
          treeCategories[i].show = true;
          treeCategories[i].ancestors = treeCategories[i].ancestors.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          for (let j = 0; j < treeCategories[i].ancestors.length; ++j) {
            if (treeCategories[i].ancestors[j].ancestors.length) {
              treeCategories[i].ancestors[j].hasIcon = true;
            } else {
              treeCategories[i].ancestors[j].hasIcon = false;
            }
          }
        } else {
          treeCategories[i].show = false;
        }
      }
      this.treeCategories = treeCategories;
      this.selectCategory(category);
    },
    selectChild(category, selectedChildCategory) {
      let treeCategories = _.cloneDeep(this.treeCategories);
      for (let i = 0; i < treeCategories.length; ++i) {
        let parentCategory = treeCategories[i];
        if (parentCategory._id == category._id) {
          // This is when child is selected
          for (let j = 0; j < treeCategories[i].ancestors.length; ++j) {
            let childCategory = treeCategories[i].ancestors[j];
            if (childCategory._id == selectedChildCategory._id) {
              treeCategories[i].ancestors[j].show = true;
              if (treeCategories[i].ancestors[j].ancestors) {
                for (let k = 0; k < treeCategories[i].ancestors[j].ancestors.length; ++k) {
                  treeCategories[i].ancestors[j].ancestors[k].show = false;
                }
              }
            } else {
              treeCategories[i].ancestors[j].show = false;
              if (treeCategories[i].ancestors[j].ancestors) {
                for (let k = 0; k < treeCategories[i].ancestors[j].ancestors.length; ++k) {
                  treeCategories[i].ancestors[j].ancestors[k].show = false;
                }
              }
            }
          }
        }
      }
      this.treeCategories = treeCategories;
      this.selectCategory(selectedChildCategory);
    },
    selectSubChild(category, selectedChildCategory) {
      let treeCategories = _.cloneDeep(this.treeCategories);
      for (let i = 0; i < treeCategories.length; ++i) {
        for (let j = 0; j < treeCategories[i].ancestors.length; ++j) {
          let parentCategory = treeCategories[i].ancestors[j];
          if (parentCategory._id == category._id) {
            for (let k = 0; k < treeCategories[i].ancestors[j].ancestors.length; ++k) {
              let childCategory = treeCategories[i].ancestors[j].ancestors[k];
              if (childCategory._id == selectedChildCategory._id) {
                treeCategories[i].ancestors[j].ancestors[k].show = true;
              } else {
                treeCategories[i].ancestors[j].ancestors[k].show = false;
              }
            }
          }
        }
      }
      this.treeCategories = treeCategories;
      this.selectCategory(selectedChildCategory);
    },
    selectCategory(value) {
      this.filterParams.categoriesOptions = [value._id];
      this.getProductsStore();
    },
    handleChangePage(page) {
      this.filterParams.page = page;
      this.getProductsStore();
    },
    quantityChange(data) {
      this.filteredItems[data.index].selectedQuantity = data.quantity;
    },
    async getTreeCategories() {
      this.fetchFilterData().then((res) => {
        this.treeCategories = _.sortBy(res.data.data.treeCategories, [
          function (o) {
            return o.name;
          },
        ]);
        this.suppliers = res.data.data.suppliers;
      });
    },
    async allOptionsData(options) {
      this.filterParams.categoriesOptions = options;
      this.getProductsStore();
    },
    queryProduct: _.debounce(function(type,options = null){
        if (type === "vendor") {
          this.filterParams.vendorOptions = options;
        }
        if (type === "search"){
          this.show(this.filterParams.categoriesOptions)
          this.filterParams.categoriesOptions = [];
          this.filterParams.vendorOptions = [];
        }
        this.getProductsStore();
      },800),
    async getProductsStore() {
      this.productFilterListing(this.filterParams).then((res) => {
        this.filteredItems = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
      });
    },

    // GRID VIEW - ACTIONS
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    getCurrentLimitations(item) {
      const limitations = _.pullAll([...this.AllLimitations].map(limitation => {
        let clinicId = '';
        let clinicsList = this.$store.state.AppActiveUser.associatedClinicIds
          .filter(({ clinicId }) => clinicId.showStore)
          .map(
            ({
              clinicId: { _id, clinicName, clinicOpeningHours, paymentTypes },
            }) => ({
              _id,
              clinicName,
              clinicOpeningHours,
              paymentTypes,
            })
          );
        if (!sessionStorage.getItem("doctorClinicSelectedClinic")) {
          clinicId = clinicsList[0]._id;
        } else {
          clinicId = sessionStorage.getItem("doctorClinicSelectedClinic");
        }
        const clinics = _.filter(limitation.clinics, ['clinicId', clinicId]);
        const products = _.filter(limitation.products, ['productId', item._id]);
        if (clinics.length > 0 && products.length > 0) {
          return limitation
        }
        return undefined
      }), [undefined]);
      this.limitationsList = limitations
    },
    async getStoreLimitiation() {
      const { data } = await this.fetchStoreLimitation();
      this.AllLimitations = data.data.docs;
      console.log(data);
    },
    additemInCart(item) {
      this.$vs.loading();
      item.shipping.shippingFee = item.supplier.deliveryDetails
        ? item.supplier.deliveryDetails.deliveryFee
        : 0;

      this.$store.dispatch("eCommerce/additemInCart", item).then((response) => {
        if(response.status === 200 && response.data.message === 'success') {
          this.getCartItemsFromApi()
          this.$vs.notify({
            color: 'success',
            title: 'Product added',
            text: 'Product was successfully added to your cart.'
          })
        } else {
          this.$vs.notify({
            color: 'danger',
            title: `Product Not Updated`,
            text: `We are having issues updating your cart. Please try again.`
          })
        }
        
        this.$vs.loading.close();
      });
    },
    async cartButtonClicked(item) {
      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      if (item.canAdd === false) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Selected quantity must not be less than " +
            item.inventory.requiredQuantity,
          color: "danger",
        });
        return false;
      }
      const cartQuantity = this.totalItemQuantityIncart(item._id);
      if (item.selectedQuantity === 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Quantity cannot be zero",
          color: "danger",
        });
        return false;
      }
      if (item.isAvailable !== true) {
        this.$vs.notify({
          title: "Failed",
          text: "Product is not available right now.",
          color: "danger",
        });
        return false;
      }
      /* if(item.selectedQuantity < item.quantity){
           this.$vs.notify({
             title: "Failed",
             text: 'Selected quantity is greater than available quantity',
             color: "danger",
           });
           return false;
         }*/
      // if((cartQuantity + item.selectedQuantity) > item.quantity){
      //   this.$vs.notify({
      //     title: "Failed",
      //     text: 'Your cart exceeds the quantity of this product.',
      //     color: "danger",
      //   });
      //   return false;
      // }

      this.additemInCart(item);
      // console.log(this.isInCart(item._id));
      // this.isInCart(item._id) ? this.$router.push('/apps/eCommerce/checkout').catch(() => {}) : this.additemInCart(item)
    },
  },
  beforeMount: async function () {
    const { email } = this.$store.state.AppActiveUser;
    const userIndex = await this.fetchAppUserIndex();
    this.getStoreLimitiation()
    const key = userIndex.data.data.count;
    const user = {
      email,
      key,
    };
    this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
    this.ldClient.on("ready", this.onLaunchDarklyReady);
    this.ldClient.on("change", this.onLaunchDarklyReady);
    this.hasOrganization = true;
  },
  created() {
    this.getTreeCategories();
    this.getProductsStore();
    this.isDoctor = this.$store.state.AppActiveUser.userType === "doctorClinic";
  },
  head() {
    return {
      link: [
        {
          rel: "stylesheet",
          href: "https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/algolia-min.css",
        },
      ],
    };
  },
  watch: {
    "$store.state.clinicId": function () {
      console.log("change????");
      this.getProductsStore();
      this.getTreeCategories();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/extraComponents/tree.scss";

button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}

div.bg-gray {
  background-color: #a0a0a0 !important;
}

#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(280px + 2.4rem);
    }
  }

  #algolia-content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }

  .item-view-secondary-action-btn {
    border-radius: 5px !important;
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}

.tree-list ul>li {
  margin-left: 20px;
  margin-bottom: 10px;
}

.tree-list li {
  margin-bottom: 10px;
}

.tree-list li ul {
  margin-top: 10px;
}

.add-to-cart-disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

[dir] .con-select .vs-select--input {
  border: 0 !important;
  padding: 13px;
  font-size: 1.1rem;
}

.vs-select--options span {
  font-size: 1.1rem;
}
</style>
